@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.navbar {
  background-color: #FFFFFF;
  margin: 0;

  .navbar-container {
    display: flex;
    height: 80px;
    max-width: 960px;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .navbar-title {
    display: inline-block;
    color: #161616;
    font-weight: 700;
    font-size: 1.75rem;
    margin-right: 1rem;
    padding: .5rem 0 1rem;
    font-family: Caveat, cursive;
  }

  .navbar-pages {
    margin-left: auto;
  }

  .navbar-link {
    font-family: 'Nunito', sans-serif;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #161616 !important;
  }

  .navbar-link:hover {
    color: #A303FF !important;
  }

  .selected {
    color: #A303FF !important;
  }

  .divider {
    background-color: #A303FF;
    height: 2px;
  }
}
