h1 {
  font-size: 32px;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #000000;
}

h2 {
  font-size: 24px;
  margin-top: 28px;
  margin-bottom: 16px;
  color: #000000;
}

h3 {
  font-size: 22px;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #434343;
}

p {
  margin-top: 16px;
  margin-bottom: 16px;
}