@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

html {
  background-color: #F8F9FA;
  font-family: 'IBM Plex Sans', sans-serif;
}

body {
  margin: 0;
  letter-spacing: 0.0625em;

  .container {
    max-width: 860px;
    margin: 36px auto;
    padding-right: 16px;
    padding-left: 16px;
  }
}

a {
  text-decoration: none;
  color: #A303FF;
}

a:hover {
  color: #7000CC;
}

footer {
  margin-top: 36px;
  text-align: center;
}
